export enum KubernetesLabels {
    video = 'video',
    video_standby = 'video_standby',
    region = 'region',
    default_region = 'default_region',
    core = 'core',
    thumb = 'thumb',
    accelerated = 'accelerated',
}

export enum KubernetesRoles {
    core = 'core',
    thumb = 'thumb',
    video = 'video',
    video_standby = 'video_standby',
    accelerated = 'accelerated',
    edge_connect = 'edge_connect',
    load_simulator = 'load_simulator',
}

export enum VideoStandbyLabelValue {
    standby = 'standby',
}

export enum VideoLabelValue {
    enabled = 'true',
}

export enum EdgeConnectLabelValue {
    enabled = 'true',
}

export enum ThumbLabelValue {
    enabled = 'true',
}

export enum AcceleratedLabelValue {
    enabled = 'true',
}

export enum DefaultRegionValue {
    true = 'true',
}

export enum LoadSimulatorLabelValue {
    enabled = 'true',
}
