import {
  Appliance,
  DeletedInput,
  Group,
  GroupInputPermission,
  GroupListInputPermission,
  Input,
  InputAccessType,
  InputAdminStatus,
  InputFilter,
  InputGraphFilter,
  InputInit,
  InputStatus,
  ListInputSortableField,
  ListResult,
  PhysicalPort,
  ServiceOverview,
  SortOrder,
} from 'common/api/v1/types'
import { UpdateInputReceiversPayload } from '../../redux/actions/inputsActions'
import { getApplianceOwnerId } from '../../utils'
import {
  EnrichedInput,
  EnrichedInputWithPorts,
  InputsRequestParams,
  OutputsRequestParams,
  singleSortQueryFromPaginatedRequestParams,
} from '../nm-types'
import { EdgeClient } from 'common/generated/edgeClient'
import { Query } from 'common/query'

export enum PermissionAction {
  'add' = 'add',
  'change' = 'change',
  'remove' = 'remove',
}

export interface IInputApi {
  createInput(input: InputInit): Promise<Input>
  getInputHealth(inputId: Input['id']): Promise<InputStatus>
  getInput(inputId: Input['id'], filter?: InputFilter): Promise<EnrichedInputWithPorts>
  getInputs(params: InputsRequestParams): Promise<ListResult<EnrichedInput>>
  getBareInputs(params: InputsRequestParams): Promise<ListResult<Input>>
  listInputs(query?: Query<InputFilter, SortOrder<ListInputSortableField>>): Promise<ListResult<Input>>
  updateInput(id: string, input: Input): Promise<Input>
  removeInput(id: string): Promise<DeletedInput>
  removeInputs(ids: Array<Input['id']>): Promise<{ deleted: Array<Input['id']>; notDeleted: Array<Input['id']> }>
  rerouteInput(id: string, avoidApplianceId?: string): Promise<Input>
  pcapInput(id: string, applianceId: string): Promise<void>
  readInputDistribution(id: string): Promise<GroupInputPermission[]>
  updateInputDistribution(
    id: string,
    permissions: Array<{ permission: GroupInputPermission; action: PermissionAction }>,
    listPermissions: { [key: string]: InputAccessType },
  ): Promise<(GroupInputPermission | GroupListInputPermission)[]>
  updateInputRecipients(params: UpdateInputReceiversPayload): Promise<void>
  getOverview(inputId: Input['id'], params: Partial<OutputsRequestParams>): Promise<ServiceOverview>
  enableInputPreview(inputId: Input['id']): Promise<void>
  enableInputs(ids: Array<Input['id']>): Promise<Input[]>
  disableInputs(ids: Array<Input['id']>): Promise<Input[]>
}

export class InputApi implements IInputApi {
  constructor(private readonly edgeClient: EdgeClient) {}
  rerouteInput(id: string, avoidApplianceId?: string): Promise<Input> {
    return this.edgeClient.rerouteInput(id, {
      avoidApplianceId,
    })
  }

  pcapInput(id: string, applianceId: string): Promise<void> {
    void applianceId
    return this.edgeClient
      .pcapInput(id, {
        applianceId,
      })
      .then(() => void 0)
  }

  createInput(input: InputInit): Promise<Input> {
    return this.edgeClient.createInput(input)
  }

  updateInput(id: string, input: Input): Promise<Input> {
    return this.edgeClient.updateInput(id, input)
  }

  removeInput(id: string): Promise<DeletedInput> {
    return this.edgeClient.deleteInput(id)
  }

  removeInputs(ids: Array<Input['id']>) {
    return this.edgeClient.deleteInputs({ ids })
  }

  readInputDistribution(id: string): Promise<Array<GroupInputPermission>> {
    return this.edgeClient.listInputDistributions(id)
  }

  /**
   * Updating groups access to the input
   * @param id - input id
   * @param permissions - GroupInputPermission and the PermissionAction to apply on it (change access type or remove access)
   * @param listPermissions - new permissions to create { [inputId ]: InputAccessType}
   */
  async updateInputDistribution(
    id: string,
    permissions: Array<{ permission: GroupInputPermission; action: PermissionAction }>,
    listPermissions: { [key: string]: InputAccessType },
  ): Promise<GroupInputPermission[]> {
    const distribution = await this.readInputDistribution(id)
    const added = permissions.filter(({ action }) => action === PermissionAction.add)

    const newDistributions = distribution
      .reduce<Array<GroupInputPermission>>((acc, perm) => {
        const edited = permissions.find(({ permission: { groupId: id } }) => perm.groupId === id)
        if (!edited) return [...acc, perm]
        if (edited.action === PermissionAction.change) {
          return [
            ...acc,
            {
              ...edited.permission,
              accessType: perm.accessType === InputAccessType.pull ? InputAccessType.view : InputAccessType.pull,
            },
          ]
        }
        return acc
      }, [])
      .concat(added.map(({ permission }) => permission))

    const groupRecipientLists: GroupListInputPermission[] = Object.entries(listPermissions).map(([key, value]) => ({
      type: 'groupList',
      groupListId: key,
      accessType: value,
    }))

    const inputDistributionResponse = await this.edgeClient.setInputDistributions(id, [
      ...newDistributions,
      ...groupRecipientLists,
    ])
    return inputDistributionResponse
  }

  /**
   * Sending/canceling sending the input to outputs
   * @param id - input id
   * @param listsAdded - outputLists id array to send
   * @param outputsAdded - output id array to send
   * @param outputsRemoved - output id array to cancel sending
   */
  async updateInputRecipients({
    id,
    listsAdded,
    outputsAdded,
    outputsRemoved,
  }: UpdateInputReceiversPayload): Promise<void> {
    await this.edgeClient.updateRecipientsInput(id, {
      addOutputIds: outputsAdded,
      removeOutputIds: outputsRemoved,
      outputRecipientLists: listsAdded,
    })
  }

  getInputHealth(id: Input['id']): Promise<InputStatus> {
    return this.edgeClient.getInputHealth(id)
  }

  /**
   * Returns input populating its ports with physical ports populated with appliance
   * @param inputId
   * @param filter
   */
  async getInput(inputId: string, filter?: InputFilter): Promise<EnrichedInputWithPorts> {
    const input: Input = await this.edgeClient.getInput(inputId, { filter })
    const ports: Array<PhysicalPort> = await Promise.all(
      (input.ports || []).map((p) =>
        // TODO: Will throw for regional ports when non-super user does not have access to appliance
        // TODO: Future improvement: Reduce number of network requests by listPorts({ filter: ids })
        this.edgeClient.getPort(p.physicalPort).catch(() => {
          // Temporary HACK to make service overview work for shared inputs (EDGE-1709)
          return {} as unknown as PhysicalPort
        }),
      ),
    )
    const applianceIds = Array.from(new Set(input.appliances?.map((a) => a.id))).filter((id) => !!id)
    const appliances: Appliance[] =
      applianceIds.length > 0 ? (await this.edgeClient.listAppliances({ filter: { ids: applianceIds } })).items : []

    const applianceOwnerIds = appliances.map(getApplianceOwnerId)
    const ownerIds = Array.from(new Set([input.owner, ...applianceOwnerIds].filter((o) => !!o) as string[]))
    const owners: Group[] =
      ownerIds.length > 0 ? (await this.edgeClient.listGroups({ filter: { ids: ownerIds } })).items : []

    const enrichedInput: EnrichedInputWithPorts = {
      ...input,
      _owner: owners.find((o) => o.id == input.owner),
      ports: (input.ports || []).map((inputPort, ind) => {
        const port = ports[ind]
        const _appliance = appliances.find(({ id }: Appliance) => port && id === port.appliance.id) as Appliance
        return {
          ...inputPort,
          _port: { ...port, _appliance },
        }
      }),
    }
    return enrichedInput
  }

  /**
   * Returns ListResult of inputs populated with their owners
   * @param requestParams
   */
  async getInputs(requestParams: InputsRequestParams): Promise<ListResult<EnrichedInput>> {
    const { items, total } = await this.getBareInputs(requestParams)
    const groupIds = Array.from(new Set(items.filter((input) => input.owner).map((input) => input.owner as string)))
    const groups = groupIds.length > 0 ? (await this.edgeClient.listGroups({ filter: { ids: groupIds } })).items : []
    return {
      items: items.map((input) => ({ ...input, _owner: groups.find((g) => g.id === input.owner) })),
      total,
    }
  }

  getBareInputs({
    canSubscribe,
    applianceId,
    appliances,
    regions,
    filter: searchName,
    derived,
    owner,
    adminStatus,
    ...params
  }: InputsRequestParams): Promise<ListResult<Input>> {
    const filter: InputFilter = {
      canSubscribe,
      applianceId,
      inputApplianceRegions: regions?.split(','),
      inputApplianceNames: appliances?.split(','),
      searchName,
      derived,
      ownerGroupName: owner,
      adminStatus:
        adminStatus && [InputAdminStatus.on.toString(), InputAdminStatus.off.toString()].includes(adminStatus)
          ? Number(adminStatus)
          : undefined,
    }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.listInputs(query)
  }

  listInputs(query?: Query<InputFilter, SortOrder<ListInputSortableField>>): Promise<ListResult<Input>> {
    return this.edgeClient.listInputs(query)
  }

  getOverview(
    inputId: Input['id'],
    { output, filter: searchName, input, inputTr101290Window, ...params }: OutputsRequestParams,
  ): Promise<ServiceOverview> {
    const filter: InputGraphFilter = { searchName, input, output, inputTr101290Window }
    const query = singleSortQueryFromPaginatedRequestParams({ filter, paginatedRequestParams: params })
    return this.edgeClient.getInputServiceOverview(inputId, query)
  }

  enableInputPreview(inputId: Input['id']) {
    return this.edgeClient.enableInputPreview(inputId)
  }

  enableInputs(ids: Input['id'][]) {
    return this.edgeClient.setInputAdminStatuses(ids.map((id) => ({ id, adminStatus: InputAdminStatus.on })))
  }

  disableInputs(ids: Input['id'][]) {
    return this.edgeClient.setInputAdminStatuses(ids.map((id) => ({ id, adminStatus: InputAdminStatus.off })))
  }
}
