import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import get from 'lodash/get'
import MuiCheckbox from '@mui/material/Checkbox'

import { Address, ApplianceType, Input, InputAdminStatus, IpPortMode, OccupiedPort } from 'common/api/v1/types'
import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, isCoreNode, makeAddressOptions } from '../../../../../utils'
import { isVaApplianceType } from 'common/applianceTypeUtil'
import { WhitelistCidrBlockAutoComplete } from '../../../../common/WhitelistCidrBlockAutoComplete'
import { SuggestedLocalPortTextField } from '../../../../common/SuggestedPortTextField'

export enum UdpFields {
  address = 'address',
  port = 'port',
  multicastAddress = 'multicastAddress',
  multicastSource = 'multicastSource',
  whitelistCidrBlock = 'whitelistCidrBlock',
}
export const udpDefaults = () =>
  createDefaultFiledValues(Object.keys(UdpFields), [], {
    [UdpFields.whitelistCidrBlock]: [],
  })

interface UdpFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  applianceType: ApplianceType
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
}

const UdpForm = ({
  form: { setFieldValue, values },
  addresses,
  namePrefix,
  applianceType,
  occupiedPorts,
  adminStatus,
}: UdpFormProps) => {
  const getMulticast = () => ({
    address: get(values, `${[namePrefix]}.multicastAddress`),
    source: get(values, `${[namePrefix]}.multicastSource`),
  })
  const { address, source } = getMulticast()
  const [isMulticast, setIsMulticast] = useState(!!(address || source))
  const [multicast, setMulticast] = useState(getMulticast())

  const addressSelector = `${namePrefix}.${UdpFields.address}`
  const selectedSourceAddress = get(values, addressSelector)
  const portSelector = `${namePrefix}.${UdpFields.port}`

  useEffect(() => {
    if (!isMulticast) {
      setMulticast(getMulticast())
      setFieldValue(`${namePrefix}.${UdpFields.multicastAddress}`, '', false)
      setFieldValue(`${namePrefix}.${UdpFields.multicastSource}`, '', false)
    } else {
      setFieldValue(`${namePrefix}.${UdpFields.multicastAddress}`, multicast.address, false)
      setFieldValue(`${namePrefix}.${UdpFields.multicastSource}`, multicast.source, false)
    }
  }, [isMulticast, setFieldValue, namePrefix])
  return (
    <>
      <Select
        name={addressSelector}
        label="Address"
        required
        options={makeAddressOptions(selectedSourceAddress, addresses)}
        newLine
        validators={{
          addressIn: { addresses },
        }}
      />
      <SuggestedLocalPortTextField
        name={portSelector}
        label={'Port'}
        namePrefix={namePrefix}
        required={true}
        portInfo={{
          mode: IpPortMode.udp,
          isInput: true,
          isMulticast,
          existingLogicalPortsOnSameNic: occupiedPorts,
          isPortDisabled: adminStatus === InputAdminStatus.off,
        }}
      />
      <Checkbox
        control={
          <MuiCheckbox checked={isMulticast} onChange={(e) => setIsMulticast(e.target.checked)} id="multicast" />
        }
        label="Is this port multicast?"
        newLine
      />
      {isMulticast && (
        <>
          <TextInput
            name={`${namePrefix}.${UdpFields.multicastAddress}`}
            label="Multicast address"
            validators={{
              multicastAddress: {},
            }}
            newLine
          />

          <TextInput
            name={`${namePrefix}.${UdpFields.multicastSource}`}
            validators={{
              ip: {},
            }}
            label="Source specific multicast address"
          />
        </>
      )}{' '}
      {!isVaApplianceType(applianceType) && (
        <WhitelistCidrBlockAutoComplete
          name={`${namePrefix}.${UdpFields.whitelistCidrBlock}`}
          required={isCoreNode(applianceType)}
        />
      )}
    </>
  )
}

export default UdpForm
