import { createSlice } from '@reduxjs/toolkit'
import { RegionDetails } from '../../api/region/api'
import { Region } from 'common/api/v1/types'
import { getRegions, getRegionDetails, clearRegions, updateRegion, deleteRegion } from '../actions/regionsActions'
import { logoutUser } from '../actions/userActions'
import { createLoadingReducer } from './shared'

interface State {
  regions: Array<Region>
  regionDetails?: RegionDetails
  total: number
  loading: boolean
  saving: boolean
}
export const initialStateRegions: State = {
  regions: [],
  total: 0,
  loading: false,
  saving: false,
}

const { isLoadingAction, loadingReducer } = createLoadingReducer<State>(getRegions, getRegionDetails)

const regionSlice = createSlice({
  name: 'region',
  initialState: initialStateRegions,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getRegions.fulfilled,
        (state, { payload: { items: regions, total } }): State => ({
          ...state,
          regions,
          total,
        }),
      )

      .addCase(
        getRegionDetails.fulfilled,
        (state, { payload: regionDetails }): State => ({
          ...state,
          regionDetails,
        }),
      )
      .addCase(clearRegions, (state): State => ({ ...state, regions: [] }))
      .addCase(logoutUser.fulfilled, (): State => initialStateRegions)

      .addCase(updateRegion.pending, (state): State => ({ ...state, saving: true }))
      .addCase(
        updateRegion.fulfilled,
        (state): State => ({
          ...state,
          saving: false,
          regionDetails: undefined,
        }),
      )
      .addCase(updateRegion.rejected, (state): State => ({ ...state, saving: false }))
      .addCase(deleteRegion.fulfilled, (state): State => ({ ...state, regionDetails: undefined }))
      .addMatcher(isLoadingAction, loadingReducer)
  },
})

export default regionSlice.reducer
