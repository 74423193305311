import { PRODUCTS as products } from '../../constants'
import {
    AnyRistServerInputMetric,
    AnyRistServerPortMode,
    Appliance,
    ApplianceConnectionState,
    ApplianceStatus,
    ApplianceType,
    ApplianceVersion,
    ArrayKeys,
    ChannelMetrics,
    CoaxPortMode,
    ComprimatoMetricType,
    ComprimatoPortMode,
    EdgeProduct,
    EntityReference,
    EntityType,
    GeneralEncoderSettings,
    Input,
    InputOperStatus,
    InputPort,
    InputStatus,
    IpcPortMode,
    IpInputPort,
    IpPortMode,
    MatroxMetricType,
    MatroxPortMode,
    MatroxSdiOutputPort,
    MetricWindow,
    MptsDemuxMetrics,
    MptsMetricType,
    Option,
    Output,
    OutputOperStatus,
    OutputPort,
    OutputStatus,
    Pid,
    PortMode,
    Restrictions,
    RistInputMetrics,
    RistMetricType,
    RistOutputMetrics,
    RistServerEgressMetric,
    RistSimpleProfileInputMetrics,
    RistSimpleProfileMetricType,
    RistSimpleProfileOutputMetrics,
    Role,
    RtmpInputMetrics,
    RtmpMetricType,
    RtmpOutputMetrics,
    RtpInputMetrics,
    RtpOutputMetrics,
    SrtInputMetrics,
    SrtMetricType,
    SrtOutputMetrics,
    StreamMetrics,
    SupportedVideoCodec,
    TrancodeStreamMetricType,
    TranscodeStreamMetrics,
    UdpInputMetrics,
    UdpOutput1mMetrics,
    UdpOutputMetrics,
    UnixInputMetrics,
    UnixOutput1mMetrics,
    UnixOutputMetrics,
    VideonInputPort,
    VideonPortMode,
    ZixiInputMetrics,
    ZixiMetricType,
    ZixiOutputMetrics,
} from './types'
import { ErrorCode, InvalidParametersError } from '../../errors'
import { RsInput as ConfigInput, RsOutput as ConfigOutput } from '../../messages'
import { TransportStream, VideoInfo } from '../../tr101Types'
import { ChannelState } from '../../rist'
import { tryStringify } from '../../serialization'
import { isReleaseGreaterThanOrEqualToVersion } from '../../buildInfoUtils'
import { isVaApplianceType } from '../../applianceTypeUtil'

export const METRICS_FRESHNESS_LIMIT_SECONDS = 30
export const MIN_AUDIO_BITRATE_BPS = 1_000 * 8 // 8 Kbps
export const MIN_VIDEO_BITRATE_BPS = 100_000 // 0.1 Mbps

// Note on value 5.5kbps:
// Inspecting an inhibited/standy RIST-stream in the service overview suggests that ~11kbps is normal bitrate for traffic only so I simply halved that.
// TODO: Ask stream team for an appropriate value
export const MIN_CONTROL_TRAFFIC_BITRATE = 5_500

export function getMinimumAcceptableInputBitrateBps(tsInfo: TransportStream | undefined): number {
    const pids = tsInfo?.pids
    if (!pids) {
        // Not a TS-stream?
        return MIN_VIDEO_BITRATE_BPS
    }
    const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)
    if (hasMpegTsAudio && hasMpegTsVideo) {
        return MIN_VIDEO_BITRATE_BPS
    }
    if (hasMpegTsVideo) {
        return MIN_VIDEO_BITRATE_BPS
    }
    if (hasMpegTsAudio) {
        return MIN_AUDIO_BITRATE_BPS
    }
    // No audio or video found in the TS-stream - only private data? We have no idea what is acceptable. Return MIN_VIDEO_BITRATE_BPS for backwards compatibility.
    return MIN_VIDEO_BITRATE_BPS
}

export const isUdpOutputOrUnixOutput1m = (
    ristMetrics: StreamMetrics
): ristMetrics is UdpOutput1mMetrics | UnixOutput1mMetrics =>
    (ristMetrics.type === 'udpOutput' || ristMetrics.type === 'unixOutput') && ristMetrics.window === MetricWindow.m1

export function outputStatus(state: OutputOperStatus, title: string): OutputStatus {
    return {
        state,
        title,
    }
}

export function inputStatus(state: InputOperStatus, title: string): InputStatus {
    return {
        state,
        title,
    }
}

export function getOutputOperState(o: Output): OutputStatus {
    return o.health || { state: OutputOperStatus.metricsMissing, title: 'Health status missing' }
}

export function isAnyRistServerInputMetrics(ristMetrics: StreamMetrics): ristMetrics is AnyRistServerInputMetric {
    return (
        ristMetrics.type === RistMetricType.ristInput ||
        ristMetrics.type === RistMetricType.udpInput ||
        ristMetrics.type === RistMetricType.unixInput ||
        ristMetrics.type === RistMetricType.rtpInput ||
        ristMetrics.type === RistSimpleProfileMetricType.ristSimpleInput
    )
}
export function isRistServerEgressMetrics(
    m: StreamMetrics
): m is RistServerEgressMetric & { isEgress: true; window: MetricWindow.s10 } {
    return m.window === MetricWindow.s10 && isRistServerEgressMetricType(m.type) && !!m.isEgress
}

export function isRistServerEgressMetricType(
    type: StreamMetrics['type'] | ComprimatoMetricType | MatroxMetricType
): type is
    | RistMetricType.udpOutput
    | RistMetricType.unixOutput
    | RistMetricType.rtpOutput
    | RistSimpleProfileMetricType.ristSimpleOutput {
    const ristServerEgressMetricTypes = [
        RistMetricType.udpOutput,
        RistMetricType.unixOutput,
        RistMetricType.rtpOutput,
        RistSimpleProfileMetricType.ristSimpleOutput,
    ] as const
    if (isMatroxMetricType(type) || isComprimatoMetricType(type)) {
        return false
    }
    return ristServerEgressMetricTypes.includes(type as (typeof ristServerEgressMetricTypes)[number])
}

function isComprimatoMetricType(
    type: StreamMetrics['type'] | ComprimatoMetricType | MatroxMetricType
): type is ComprimatoMetricType {
    return type in ComprimatoMetricType
}
function isMatroxMetricType(
    type: StreamMetrics['type'] | ComprimatoMetricType | MatroxMetricType
): type is MatroxMetricType {
    return type in MatroxMetricType
}

export function getMetricTypeForOutput(outputPortMode: OutputPort['mode'], applianceType: ApplianceType) {
    if (isVaApplianceType(applianceType)) {
        return RistMetricType.rtpOutput
    }
    switch (outputPortMode) {
        case CoaxPortMode.asi:
            return RistMetricType.rtpOutput
        case CoaxPortMode.sdi:
            return RistMetricType.rtpOutput
        case IpPortMode.udp:
            return RistMetricType.udpOutput
        case IpPortMode.rtp:
            return RistMetricType.rtpOutput
        case IpPortMode.rist:
            return RistSimpleProfileMetricType.ristSimpleOutput
        case IpPortMode.srt:
            return SrtMetricType.srtOutput
        case IpPortMode.zixi:
            return ZixiMetricType.zixiOutput
        case IpPortMode.rtmp:
            return RtmpMetricType.rtmpOutput
        case MatroxPortMode.matroxSdi:
            return MatroxMetricType.matroxSdiOutput
        case ComprimatoPortMode.comprimatoNdi:
            return ComprimatoMetricType.comprimatoNdiOutput
        case ComprimatoPortMode.comprimatoSdi:
            return ComprimatoMetricType.comprimatoSdiOutput
        case IpcPortMode.unix:
            return RistMetricType.unixOutput
    }
}

export function isRistInputMetrics(m: StreamMetrics): m is RistInputMetrics {
    return m.type === RistMetricType.ristInput
}

export function isRistOutputMetrics(m: StreamMetrics): m is RistOutputMetrics {
    return m.type === RistMetricType.ristOutput
}
export function isUnixInputMetrics(m: StreamMetrics): m is UnixInputMetrics {
    return m.type === RistMetricType.unixInput
}

export function isUdpInputMetrics(m: StreamMetrics): m is UdpInputMetrics {
    return m.type === RistMetricType.udpInput
}

export function isOutputMetricsWithPacketsLost(m: StreamMetrics): m is UdpOutputMetrics | RtpOutputMetrics {
    return isUdpOutputMetrics(m) || isRtpOutputMetrics(m)
}

export function isUdpOutputMetrics(m: StreamMetrics): m is UdpOutputMetrics {
    return m.type === RistMetricType.udpOutput
}

export function isUnixOutputMetrics(m: StreamMetrics): m is UnixOutputMetrics {
    return m.type === RistMetricType.unixOutput
}
export function isUdpOutputOrUnixOutputMetrics(m: StreamMetrics): m is UdpOutputMetrics | UnixOutputMetrics {
    return isUdpOutputMetrics(m) || isUnixOutputMetrics(m)
}

export function isRtpInputMetrics(m: StreamMetrics): m is RtpInputMetrics {
    return m.type === RistMetricType.rtpInput
}

export function isMptsMetrics(m: StreamMetrics): m is MptsDemuxMetrics {
    return m.type === MptsMetricType.mptsDemux
}

export function isTranscodeStreamMetrics(m: StreamMetrics): m is TranscodeStreamMetrics {
    return m.type === TrancodeStreamMetricType.transcodeStream
}

export function isRtpOutputMetrics(m: StreamMetrics): m is RtpOutputMetrics {
    return m.type === RistMetricType.rtpOutput
}

export function isRistSimpleProfileInputMetrics(m: StreamMetrics): m is RistSimpleProfileInputMetrics {
    return m.type === RistSimpleProfileMetricType.ristSimpleInput
}

export function isRistSimpleProfileOutputMetrics(m: StreamMetrics): m is RistSimpleProfileOutputMetrics {
    return m.type === RistSimpleProfileMetricType.ristSimpleOutput
}

export function isZixiInputMetrics(m: StreamMetrics): m is ZixiInputMetrics {
    return m.type === ZixiMetricType.zixiInput
}

export function isZixiOutputMetric(m: StreamMetrics): m is ZixiOutputMetrics {
    return m.type === ZixiMetricType.zixiOutput
}

export function isSrtInputMetric(m: StreamMetrics): m is SrtInputMetrics {
    return m.type === SrtMetricType.srtInput
}

export function isSrtOutputMetric(m: StreamMetrics): m is SrtOutputMetrics {
    return m.type === SrtMetricType.srtOutput
}

export function isRtmpInputMetric(m: StreamMetrics): m is RtmpInputMetrics {
    return m.type === RtmpMetricType.rtmpInput
}

export function isRtmpOutputMetric(m: StreamMetrics): m is RtmpOutputMetrics {
    return m.type === RtmpMetricType.rtmpOutput
}

export function isRistServerChannelMetrics(ristMetrics: StreamMetrics): ristMetrics is ChannelMetrics {
    return ristMetrics.type === RistMetricType.channel
}

export function isRistServerPortMode(mode?: PortMode): mode is AnyRistServerPortMode {
    return mode === IpPortMode.udp || mode === IpPortMode.rtp || mode === IpPortMode.rist
}

export function isMatroxPortMode(portMode: InputPort['mode']): portMode is MatroxPortMode {
    return portMode in MatroxPortMode
}

export function isIpPortMode(portMode: InputPort['mode']): portMode is IpPortMode {
    return portMode in IpPortMode
}

export function isIpPort(inputPort: InputPort): inputPort is IpInputPort {
    return isIpPortMode(inputPort.mode)
}

export function isVideonPortMode(portMode: InputPort['mode'] | OutputPort['mode']): portMode is VideonPortMode {
    return portMode in VideonPortMode
}
// There are currently no Videon output ports
export function isVideonPort(port: InputPort | OutputPort): port is VideonInputPort {
    return isVideonPortMode(port.mode)
}

export function isComprimatoPortMode(portMode: InputPort['mode']): portMode is ComprimatoPortMode {
    return portMode in ComprimatoPortMode
}

export const getInputOperState = (input: Input): InputStatus => {
    return input.health || { state: InputOperStatus.metricsMissing, title: 'Health status missing' }
}

export function getActiveChannelOnAppliance(applianceId: string, ristMetrics: StreamMetrics[]): number | undefined {
    return (
        ristMetrics.find(
            (m) =>
                m.type === RistMetricType.channel && m.applianceId === applianceId && m.state === ChannelState.activated
        )?.channelId ??
        // Backwards compatibility for appliances that don't have "channel.state" introduced in R3.15.0: Use first found channelId
        ristMetrics.find((m) => m.type === RistMetricType.channel && m.applianceId === applianceId)?.channelId
    )
}

export function ageSeconds(d: Date | string, dateNow: Date = new Date()) {
    let date
    if (typeof d === 'string') {
        date = Date.parse(d)
    } else {
        date = d.valueOf()
    }

    return Math.floor((dateNow.valueOf() - date) / 1000)
}

export const getProductName = (type: Appliance['type']): string =>
    ({
        [ApplianceType.nimbraVAdocker]: 'Edge Connect+',
        [ApplianceType.nimbra410]: 'Nimbra 410',
        [ApplianceType.nimbra412]: 'Nimbra 412',
        [ApplianceType.nimbra414]: 'Nimbra 414',
        [ApplianceType.nimbra414b]: 'Nimbra 414b',
        [ApplianceType.nimbraVA225]: 'Nimbra VA 225',
        [ApplianceType.nimbraVA220]: 'Nimbra VA 220',
        [ApplianceType.edgeConnect]: 'Edge Connect',
        [ApplianceType.core]: 'Core node',
        [ApplianceType.thumb]: 'Core thumb',
        [ApplianceType.accelerated]: 'Core accelerated',
        [ApplianceType.videon]: 'Videon',
        [ApplianceType.matroxMonarchEdgeE4_8Bit]: 'Monarch EDGE E4 (8 bit)',
        [ApplianceType.matroxMonarchEdgeE4_10Bit]: 'Monarch EDGE E4 (10 bit)',
        [ApplianceType.matroxMonarchEdgeD4]: 'Monarch EDGE D4',
        [ApplianceType.matroxMonarchEdgeS1]: 'Monarch EDGE S1',
        [ApplianceType.comprimato]: 'Comprimato',
        [ApplianceType.mediakindRx1]: 'Mediakind RX1',
        [ApplianceType.mediakindCe1]: 'Mediakind CE1',
    }[type] || type)

export const getTransferredString = (gbValue?: number) => {
    if (gbValue === undefined) {
        return
    }

    let measure = 'GB'
    let value: number | string = gbValue
    if (Math.floor(gbValue) > 0) {
        value = gbValue.toFixed(2)
    } else {
        measure = 'MB'
        value = (gbValue * 1e3).toFixed(2)
        if (parseFloat(value) === 0) {
            value = `~${value}`
        }
    }
    return `${value} ${measure}`
}

export const validatePassword = (value: string): string | undefined => {
    const minPwdLength = 8
    if (value.length > 0) {
        if (value.length < minPwdLength) {
            return `Password must contain at least ${minPwdLength} characters`
        }
    }
    return undefined
}

function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/m

export const validateUsername = (value: string): string | undefined => {
    if (!emailIsValid(value)) {
        return `Must be a valid email address`
    }
    return undefined
}

export const validate = (fieldname: string, validationErrorInfo: string | undefined) => {
    if (validationErrorInfo) {
        throw new InvalidParametersError(ErrorCode.invalidParameters, [
            { name: fieldname, reason: validationErrorInfo },
        ])
    }
}

export const getEntityTypeName = (entityType: EntityType): string => {
    switch (entityType) {
        case EntityType.outputRecipientList:
            return 'output list'
        case EntityType.groupRecipientList:
            return 'group list'
        default:
            return entityType
    }
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined
}

export function notUndefinedOrNull<T>(x: T | undefined | null): x is T {
    return x !== undefined && x !== null
}

export function getFormattedTransportStreamContent(tsInfo: TransportStream | undefined): string {
    if (!tsInfo) {
        return 'N/A'
    }
    const pids = tsInfo.pids
    if (!pids) {
        return 'No pids'
    }
    const streamIsMpts = isMpts([tsInfo])
    if (streamIsMpts) {
        return 'MPTS'
    }

    const { hasMpegTsAudio, hasMpegTsVideo } = getTransportStreamContentInfo(pids)
    if (hasMpegTsVideo) {
        return videoFormatFromTransportStream(pids)
    } else if (hasMpegTsAudio) {
        return audioFormatFromTransportStream(pids)
    }
    return `No audio/video`
}

export function getInputPidsOnInputAppliance(input?: Input, channelId?: number) {
    if (!input || !channelId) {
        return []
    }

    const inputApplianceIds = input.appliances?.map((appliance) => appliance.id)
    if (!inputApplianceIds) {
        return []
    }
    const tsInfo = input.tsInfo
    if (!tsInfo) {
        return []
    }
    const inputApplianceTsInfo = tsInfo.find(
        (tsInfo) => inputApplianceIds.includes(tsInfo.applianceId) && tsInfo.channelId === channelId
    )
    return inputApplianceTsInfo?.pids || []
}

export function getTransportStreamContentInfo(pids: (Pid | undefined)[]): {
    hasMpegTsAudio: boolean
    hasMpegTsVideo: boolean
} {
    return {
        hasMpegTsAudio: pids.some((p) => p?.streamInfo?.audio !== undefined),
        hasMpegTsVideo: pids.some((p) => p?.streamInfo?.video !== undefined),
    }
}

export function tsInfoServiceName(info?: TransportStream) {
    const firstService = info?.services?.[0]
    const noInfo = 'no info'
    if (!firstService) {
        return noInfo
    }
    return `${firstService.name || firstService.type?.description || noInfo}`
}

export function audioFormatFromTransportStream(tsPids: (Pid | undefined)[]): string {
    // TODO: Handle MPTS - we currently extract the first found audio entry
    const audioPid = tsPids.find((p) => p?.streamInfo?.audio)
    if (!audioPid) {
        return 'No audio'
    }
    const audio = audioPid.streamInfo!.audio!
    const sampleRate = audio.sampleRate ? `${audio.sampleRate / 1000}kHz ` : ''
    const codec = getFormattedElementaryStreamType(audioPid)
    return `${sampleRate}${codec}`
}

function formattedRate(video: VideoInfo) {
    if (!video.frameRate) return ''
    const framerate = video.frameRate
    const fieldrate = framerate * 2
    return (video.interlaced === 'yes' ? fieldrate : framerate).toFixed(2)
}
export function videoFormatFromTransportStream(tsPids: (Pid | undefined)[]): string {
    // TODO: Handle MPTS - we currently extract the first found video entry
    const videoPid = tsPids.find((p) => p?.streamInfo?.video)
    if (!videoPid) {
        return 'No video'
    }
    const video = videoPid.streamInfo!.video!
    const videoSize = video.videoSize ? video.videoSize.vertical : ''
    const interlaced = video.interlaced ? (video.interlaced === 'yes' ? 'i' : 'p') : '?'
    const resolution = `${videoSize}${interlaced}`
    const frameOrFieldrate = formattedRate(video)
    const codec = getFormattedElementaryStreamType(videoPid)
    return `${resolution}${frameOrFieldrate} ${codec}`
}

export const isOutput = (inputOrOutput: ConfigInput | ConfigOutput): inputOrOutput is ConfigOutput => {
    return (
        inputOrOutput.type === 'push-rist-output' ||
        inputOrOutput.type === 'rist-simple-output' ||
        inputOrOutput.type === 'srt-output' ||
        inputOrOutput.type === 'zixi-output' ||
        inputOrOutput.type === 'udp-output' ||
        inputOrOutput.type === 'rtp-output' ||
        inputOrOutput.type === 'rtmp-output'
    )
}

export function formatBitrate(bps?: number | null | string) {
    if (typeof bps === 'string') {
        // eslint-disable-next-line no-param-reassign
        bps = parseInt(bps)
    }
    if (typeof bps !== 'number' || !Number.isFinite(bps)) {
        return 'N/A'
    }
    const units = [
        ['kbps', 0],
        ['Mbps', 2],
        ['Gbps', 3],
        ['Tbps', 3],
    ] as const
    const absBps = Math.max(bps, 0)
    const log = Math.floor(Math.log10(absBps))
    const size = Math.max(Math.floor(log / 3) - 1, 0)
    const [unit, decimals] = units[size]
    const val = absBps / Math.pow(10, (size + 1) * 3)
    const formattedVal = val.toFixed(decimals)
    return `${formattedVal} ${unit}`
}

export function getApplianceStatus(
    appliance: Pick<Appliance, 'lastMessageAt'>,
    formatDistanceToNow: (date: Date) => string
): ApplianceStatus {
    const applianceOnlineThresholdSeconds = 15
    if (!appliance.lastMessageAt) {
        return {
            title: `Never connected`,
            state: ApplianceConnectionState.neverConnected,
        }
    }
    if (ageSeconds(appliance.lastMessageAt) > applianceOnlineThresholdSeconds) {
        return {
            title: `Last seen ${formatDistanceToNow(appliance.lastMessageAt)} ago`,
            state: ApplianceConnectionState.missing,
        }
    }
    return {
        title: `Last seen less than ${applianceOnlineThresholdSeconds} seconds ago`,
        state: ApplianceConnectionState.connected,
    }
}

export function formatGraphNodeId({ type, id }: EntityReference) {
    return `${type}::${id}`
}

export function splitGraphNodeId(nodeId: string): EntityReference {
    const [type, id] = nodeId.split('::')
    return { type, id } as EntityReference
}

export enum ElementaryStreamTypeName {
    h262 = 'h262',
    mpeg1 = 'mpeg-1',
    mpeg2 = 'mpeg-2',
    adtsAac = 'adts-aac',
    h263 = 'h263',
    h264 = 'h264',
    j2k = 'j2k',
    h265 = 'h265',
    jpegxs = 'jpeg-xs',
    ac3 = 'ac3',
    eac3 = 'eac3',
}

export function isMpts(tsInfo?: Input['tsInfo']) {
    return tsInfo?.some((tsInfo) => (tsInfo.services?.length || 1) > 1)
}

export function getVideoCodec(tsInfo?: Input['tsInfo']): ElementaryStreamTypeName | null {
    if (!tsInfo) {
        return null
    }
    // note: channelId stores priority in highest bits
    const thumbTsInfo = tsInfo
        .filter((t) => t.applianceType === ApplianceType.thumb)
        .sort((a, b) => (a.channelId ?? 0) - (b.channelId ?? 0))
    const videoPid = thumbTsInfo[0]?.pids?.find((p) => p?.streamInfo?.video !== undefined)
    if (!videoPid) {
        return null
    }

    return getElementaryStreamType(videoPid.streamInfo?.type?.value)
}

export function getAudioCodec(tsInfo?: Input['tsInfo']): ElementaryStreamTypeName | null {
    if (!tsInfo) {
        return null
    }
    const audioPid = tsInfo[0]?.pids?.find((p) => p?.streamInfo?.audio !== undefined)
    if (!audioPid) {
        return null
    }

    return getElementaryStreamType(audioPid.streamInfo?.type?.value)
}

function getFormattedElementaryStreamType(pid: Pid): string {
    const streamTypeValue = pid.streamInfo?.type?.value
    if (streamTypeValue) {
        const mappedStreamType = getElementaryStreamType(streamTypeValue)
        if (mappedStreamType !== null) {
            return mappedStreamType.toString()
        }
    }

    const pidDescription = pid.description
    if (pidDescription) {
        return pidDescription
    }

    if (streamTypeValue) {
        return `(${streamTypeValue})`
    }

    return '?'
}

/**
 * https://en.wikipedia.org/wiki/Program-specific_information#Elementary_stream_types
 */
function getElementaryStreamType(streamType?: number): ElementaryStreamTypeName | null {
    switch (streamType) {
        case 1:
            return ElementaryStreamTypeName.mpeg1 // Video
        case 2:
            return ElementaryStreamTypeName.h262
        case 3:
            return ElementaryStreamTypeName.mpeg1 // Audio
        case 4:
            return ElementaryStreamTypeName.mpeg2 // Audio
        case 15:
            return ElementaryStreamTypeName.adtsAac // Audio
        case 16:
            return ElementaryStreamTypeName.h263
        case 27:
            return ElementaryStreamTypeName.h264
        case 33:
            return ElementaryStreamTypeName.j2k
        case 36:
            return ElementaryStreamTypeName.h265
        case 50:
            return ElementaryStreamTypeName.jpegxs
        case 129:
            return ElementaryStreamTypeName.ac3 // Audio
        case 132:
            return ElementaryStreamTypeName.eac3 // Audio
        case 135:
            return ElementaryStreamTypeName.eac3 // Audio
    }

    return null
}

// return start of month in UTC
export const startOfMonthUTC = (value: Date): Date => {
    const month = value.getUTCMonth() + 1
    const monthStr = month < 10 ? `0${month}` : `${month}`
    return new Date(`${value.getUTCFullYear()}-${monthStr}-01T00:00:00.000Z`)
}

export function endOfMonthUTC(d: Date): Date {
    const endDate = new Date(d)
    endDate.setUTCDate(1)
    endDate.setUTCMonth(endDate.getUTCMonth() + 1)
    endDate.setUTCDate(0)
    endDate.setUTCHours(23, 59, 59, 999)
    return endDate
}

export function addMonthUTC(d: Date, n: number = 1) {
    const addedMonth = new Date(d)
    const date = addedMonth.getUTCDate()
    addedMonth.setUTCDate(1)
    const nextMonth = addedMonth.getUTCMonth() + n
    addedMonth.setUTCMonth(nextMonth)
    addedMonth.setUTCDate(Math.min(date, endOfMonthUTC(addedMonth).getUTCDate()))
    return addedMonth
}

export const dateToYearMonthDayString = (d: Date): string => {
    return d.toISOString().substring(0, 10)
}

export const dateToYearMonthDayMaxNowString = (d: Date): string => {
    d = d.getTime() > Date.now() ? new Date() : d
    return dateToYearMonthDayString(d)
}

export const startOfDayUTC = (d: Date): Date => {
    return new Date(dateToYearMonthDayString(d))
}

export const endOfDayUTC = (d: Date): Date => {
    return new Date(`${dateToYearMonthDayString(d)}T23:59:59.999Z`)
}

export const endOfDayMaxNowUTC = (d: Date): Date => {
    const endOfDay = endOfDayUTC(d)
    return endOfDay.getTime() > Date.now() ? new Date() : endOfDay
}

export function prettyFormatError(e: any): string | undefined {
    const str = e?.toString()
    if (str === '[object Object]') {
        return tryStringify(e)
    }
    return str
}

export function isIpcPortMode(portMode: InputPort['mode']): portMode is IpcPortMode {
    return portMode in IpcPortMode
}

export const DATE_FORMAT_SHORT = 'yyyy-MM-dd'
export const DATE_FORMAT_LONG = 'yyyy-MM-dd HH:mm:ss'

export function omit<T extends Record<string, any>, K extends keyof T>(t: T, ...omitKeys: K[]) {
    const o = { ...t }
    for (const k of omitKeys) {
        delete o[k]
    }
    return o as Omit<T, K>
}

export const roleLevels: readonly Role[] = Object.freeze([Role.basic, Role.admin, Role.super])

export function getRoleLevel(role: Role) {
    const index = roleLevels.findIndex((r) => r === role)
    if (index === -1) {
        throw new Error(`Invalid role: ${role}`)
    }
    return index
}

export function maxRole(...roles: Role[]) {
    if (roles.length === 0) {
        throw new Error(`maxRole expects at least one role as input`)
    }
    let maxRole: Role = roles[0]
    for (const role of roles) {
        if (getRoleLevel(role) > getRoleLevel(maxRole)) {
            maxRole = role
        }
    }
    return maxRole
}

export function applyCodecRestrictions<
    T extends SupportedVideoCodec,
    Prop extends Exclude<ArrayKeys<T>, 'restrictions'>
>(
    options: T[Prop],
    property: Prop,
    encoderSettings: GeneralEncoderSettings,
    restrictions?: Restrictions<T>
): NonNullable<T[Prop]> {
    // Yes, the typing here could be improved
    const opts = options || ([] as any[])
    if (!Array.isArray(opts)) {
        return opts as unknown as NonNullable<T[Prop]>
    }
    const propRestrictions = restrictions && restrictions[property]
    if (!propRestrictions) {
        return opts as unknown as NonNullable<T[Prop]>
    }
    const determinant = propRestrictions.determinedBy
    if (!(determinant in encoderSettings)) {
        return opts as unknown as NonNullable<T[Prop]>
    }
    const determinantValue = encoderSettings[determinant as keyof typeof encoderSettings]
    const mappedValues = propRestrictions.mapping[determinantValue as keyof typeof propRestrictions.mapping]
    if (!Array.isArray(mappedValues)) {
        return opts as unknown as NonNullable<T[Prop]>
    }
    return opts.filter((o) => {
        return mappedValues.includes(isNameValueOption(o) ? o.value : o)
    }) as unknown as NonNullable<T[Prop]>
}

export function isNameValueOption(v: any): v is Option {
    return typeof v === 'object' && typeof v['name'] === 'string' && 'value' in v
}

export function supportsUnixSockets(applianceVersion: ApplianceVersion) {
    if (applianceVersion.dataSoftwareVersion === 'ci-690874508') {
        // For testing purpose, ci-690874508 is a master build > R3.13.0 and < R3.14.0
        return false
    }
    return isReleaseGreaterThanOrEqualToVersion('R3.14.0', applianceVersion.dataSoftwareVersion)
}

export function formatOutputStreamContents({ ports, tsInfo }: { ports: Output['ports']; tsInfo?: TransportStream[] }) {
    if (isMatroxPortMode(ports[0].mode)) {
        return formatFromMatroxOutputPort(ports[0] as MatroxSdiOutputPort)
    } else {
        return getFormattedTransportStreamContent((tsInfo || [])[0])
    }
}

export function productIdFromEdgeProduct(edgeProduct: EdgeProduct) {
    switch (edgeProduct) {
        case EdgeProduct.loci:
            return products.connectIt.id
        case EdgeProduct.edge:
            return products.nimbraEdge.id
    }
}

function formatFromMatroxOutputPort(port: MatroxSdiOutputPort) {
    return port.decoderSettings.outputSettings.resolution
}
