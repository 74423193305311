import { ExpFeatures, GlobalSettings } from 'common/api/v1/types'

const featureNames: { [k in ExpFeatures]: string } = {
  ExtDerivedInput: 'Create derived inputs',
  ExtSelectableDelayMode: 'Choose output delay mode',
  AllowLegacyExternalRegions: 'Allow legacy external regions (< R3.14.0)',
  OASResponseValidation: 'OAS response validation',
}

export function getExperimentalDevFeatures(): { name: string; label: string }[] {
  return Object.keys(ExpFeatures)
    .filter((key) => !key.startsWith('Ext'))
    .map((key) => ({
      name: key,
      label: featureNames[key as ExpFeatures] || key,
    }))
}

export function getExperimentalExternalFeatures(): { name: string; label: string }[] {
  return Object.keys(ExpFeatures)
    .filter((key) => key.startsWith('Ext'))
    .map((key) => ({
      name: key,
      label: featureNames[key as ExpFeatures] || key.replace(/^Ext/, ''),
    }))
}

export function isFeatureOn(feature: ExpFeatures, settings: GlobalSettings): boolean {
  return !!settings.expFeatures[feature]
}
