import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Theme } from '@mui/material/styles'

import { Link } from './Link'
import { TopLeftLogo, TopLeftLogoLoci } from './LogoFetcher'
import { getIsStreamManager } from '../../utils'
import { GlobalState } from '../../store'
import { Role } from 'common/api/v1/types'
import { useLocation } from 'react-router-dom'
import { EnrichedUser } from '../../api/nm-types'
import { IS_CONNECT_IT } from '../../env'

import { useRoutes } from '../../store'

const styles = {
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
  },
  system: {
    lineHeight: '14px',
  },
  group: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonWrapper: {
    textTransform: 'none',
    overflow: 'hidden',
  },
  icon: {
    marginLeft: '4px',
    color: (theme: Theme) => theme.palette.text.secondary,
  },
}

interface LogoProps {
  linkTo: string
}

/**
 * Logo for both network manager and Stream manager top left corner
 * @param linkTo - Link of TopLeftLogo
 */
const Logo = ({ linkTo }: LogoProps) => {
  const { pathname } = useLocation()
  const routes = useRoutes()
  const isStreamManager = getIsStreamManager(pathname, routes)
  const user = useSelector(({ userReducer }: GlobalState) => userReducer.user as EnrichedUser, shallowEqual)

  return (
    <Box sx={styles.logoWrapper}>
      <Link to={linkTo}>{IS_CONNECT_IT ? <TopLeftLogoLoci /> : <TopLeftLogo />}</Link>
      <Link to={isStreamManager ? routes.overview() : routes.stream()} style={{ marginLeft: '8px' }}>
        {user.role !== Role.basic && (
          <Button sx={{ width: '100%' }}>
            <Box sx={styles.buttonWrapper}>
              <Typography component="div" sx={styles.system} variant="caption" color="textPrimary" align="left">
                {isStreamManager ? 'Stream manager' : 'Network Manager'}
              </Typography>
              <Typography component="div" sx={styles.group} variant="caption" color="textSecondary" align="left">
                {!!user._group && user._group.name}
              </Typography>
            </Box>
            <ExpandMore sx={styles.icon} />
          </Button>
        )}
      </Link>
    </Box>
  )
}

export default Logo
