import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Form, Formik, FormikProps } from 'formik'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MuiLink from '@mui/material/Link'
import Tooltip from '../common/Tooltip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import HelpOutline from '@mui/icons-material/HelpOutline'
import MoreVert from '@mui/icons-material/MoreVert'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Theme } from '@mui/material/styles'
import { IS_CONNECT_IT } from '../../env'

import {
  BackupInit,
  ExpFeatures,
  GlobalSettings,
  ImageName,
  KubernetesProvider,
  LogLevel,
  Role,
  TlsCertRead,
  TlsCertWrite,
  User,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState, useRoutes } from '../../store'
import {
  getSettings,
  saveImages,
  saveSettings,
  saveTls,
  setDevMode,
  createBackup,
} from '../../redux/actions/settingsActions'
import { numericEnum, useConfirmationDialog, usePageParams } from '../../utils'
import { getExperimentalDevFeatures, getExperimentalExternalFeatures } from '../../utils/features'
import { ButtonsPane, Checkbox, GridItem, Paper, Select, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import { Link } from '../common/Link'
import FileInput from './FileInput'
import { ensureOperatorToken } from '../../redux/actions/apiTokensActions'

const classes = {
  container: {
    width: '100%',
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
  noMarginTop: {
    mt: 0,
    WebkitBorderTopLeftRadius: 0,
    WebkitBorderTopRightRadius: 0,
  },
  noMarginBottom: {
    mb: 0,
    WebkitBorderBottomLeftRadius: 0,
    WebkitBorderBottomRightRadius: 0,
  },
}

enum GlobalSettingsTabs {
  settings = 'settings',
  'customization' = 'customization',
  'tls certificate' = 'tls certificate',
  licenses = 'licenses',
  alarms = 'alarms',
}

const SettingsBackupsPane = () => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()
  const [anchorElConfig, setAnchorElConfig] = useState<HTMLElement | null>(null)
  const [anchorElMetrics, setAnchorElMetrics] = useState<HTMLElement | null>(null)

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics,
  ) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics) => {
    setAnchorEl(null)
  }

  const handleCreateBackup = (
    type: BackupInit['type'],
    setAnchorEl: typeof setAnchorElConfig | typeof setAnchorElMetrics,
  ) => {
    showConfirmation(() => {
      dispatch(createBackup({ type }))
      setAnchorEl(null)
    }, 'Taking backup might temporarily affect the database performance and will use additional disk space. Would you like to proceed?')
  }

  return (
    <Paper title="Backups">
      <GridItem>
        <Typography component="span">Config backups</Typography>
        <Tooltip
          title="Config backups are created every 6 hours. The retention period is 7 days."
          sx={{ marginLeft: 1 }}
        >
          <HelpOutline />
        </Tooltip>
        <Tooltip title="View config backups">
          <MuiLink href={'/backup/config'} target="_blank">
            <IconButton aria-label="View config backups">
              <OpenInNew />
            </IconButton>
          </MuiLink>
        </Tooltip>
        <Box>
          <IconButton onClick={(e) => handleOpen(e, setAnchorElConfig)} aria-label="Show actions of config backups">
            <MoreVert />
          </IconButton>
          <Menu
            open={Boolean(anchorElConfig)}
            keepMounted
            anchorEl={anchorElConfig}
            onClose={() => handleClose(setAnchorElConfig)}
          >
            <MenuItem onClick={() => handleCreateBackup('config', setAnchorElConfig)}>Take config backup</MenuItem>
            <MenuItem onClick={() => handleCreateBackup('billing', setAnchorElConfig)}>Take billing backup</MenuItem>
          </Menu>
        </Box>
      </GridItem>
      <GridItem newLine>
        <Typography component="span">Metrics backups</Typography>
        <Tooltip
          title={<>Automatic backup is currently disabled. The retention period is 7 days.</>}
          sx={{ marginLeft: 1 }}
        >
          <HelpOutline />
        </Tooltip>
        <Tooltip title={!IS_CONNECT_IT ? 'View metrics backups' : 'Not available in Connect iT'}>
          <MuiLink href={'/backup/metrics'} target="_blank">
            <IconButton aria-label="View metrics backups" disabled={IS_CONNECT_IT}>
              <OpenInNew />
            </IconButton>
          </MuiLink>
        </Tooltip>
        <Box>
          <IconButton
            disabled={IS_CONNECT_IT}
            onClick={(e) => handleOpen(e, setAnchorElMetrics)}
            aria-label="Show actions of metrics backups"
          >
            <MoreVert />
          </IconButton>
          <Menu
            open={Boolean(anchorElMetrics)}
            keepMounted
            anchorEl={anchorElMetrics}
            onClose={() => handleClose(setAnchorElMetrics)}
          >
            <MenuItem onClick={() => handleCreateBackup('metrics', setAnchorElMetrics)}>Take metrics backup</MenuItem>
          </Menu>
        </Box>
      </GridItem>
    </Paper>
  )
}

const SettingsForm = ({ values }: FormikProps<GlobalSettings>) => {
  const { saving, devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()
  const routes = useRoutes()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="settings-form" translate="no" noValidate>
          <Paper title="Settings" sx={classes.noMarginTop}>
            <Select
              label="Edge API log level"
              name="logLevel"
              options={Object.entries(numericEnum(LogLevel)).map(([name, value]) => ({ name, value }))}
            />
            <TextInput name="defaultDelay" label="Default delay" required type="number" noNegative />
            <Select
              label="Default broadcast standard"
              name="defaultBroadcastStandard"
              options={[
                { name: 'DVB', value: 'dvb' },
                { name: 'ATSC', value: 'atsc' },
              ]}
            />
            {!IS_CONNECT_IT && (
              <>
                <Checkbox
                  name="sslRedirectEnabled"
                  label="SSL redirection enabled"
                  tooltip={'Redirect from HTTP to HTTPS (if TLS is enabled)'}
                />
                <Checkbox
                  name="ntpEnabled"
                  label="NTP enabled"
                  disabled={values.kubernetesProvider !== KubernetesProvider.metal}
                />
                <TextInput name="ntpServer" label="NTP server" disabled={!values.ntpEnabled} />
              </>
            )}
          </Paper>
          <Paper title="Optional features">
            {getExperimentalExternalFeatures().map((feature) => (
              <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
            ))}
          </Paper>
          <SettingsBackupsPane />
          {devMode && (
            <Paper title="Experimental features">
              <GridItem>
                <Button onClick={() => dispatch(setDevMode(false))} id="disable-dev-mode-button" variant="outlined">
                  Disable dev mode
                </Button>
              </GridItem>
              <GridItem newLine>
                <Button
                  onClick={() => void dispatch(ensureOperatorToken())}
                  id="ensure-operator-token-button"
                  variant="contained"
                >
                  Ensure Edge operator token
                </Button>
              </GridItem>
              <Box sx={{ width: '100%' }} />
              {getExperimentalDevFeatures().map((feature) => (
                <Checkbox key={feature.name} label={feature.label} name={`expFeatures.${feature.name}`} />
              ))}
              <GridItem>
                <Link to={routes.status()} underline="hover">
                  Status Page
                </Link>
              </GridItem>
            </Paper>
          )}
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const TlsForm = () => {
  const { savingTls } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form translate="no" noValidate>
          <Paper title="TLS Certificate" sx={classes.noMarginTop}>
            <TextInput name="fingerprint" label="Fingerprint" disabled />
            <TextInput name="expiresAt" label="Expires at" disabled />
            <TextInput
              name="key"
              label="Private key"
              required
              newLine
              multiline
              tooltip="Paste the RSA or ECC private key in PEM format here"
            />
            <TextInput
              name="cert"
              label="Certificate"
              required
              multiline
              tooltip="Paste the RSA or ECC certificate in PEM format here"
            />
          </Paper>
          <ButtonsPane
            main={{
              Save: { primary: true, savingState: savingTls, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const CustomizationForm = () => {
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Form id="customization-form" translate="no" noValidate style={{ width: '100%' }}>
      <Paper sx={classes.noMarginTop}>
        <TextInput name="customTabTitle" label="Tab title" />
      </Paper>
      <ButtonsPane
        main={{
          Save: { primary: true, savingState: saving, type: 'submit' },
        }}
      />
    </Form>
  )
}

const LicenseForm = () => {
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="licenses-form" translate="no" noValidate>
          <Paper title="Licenses" sx={classes.noMarginTop}>
            <TextInput name="zixiFeederKey" label="Zixi feeder key" />
            <TextInput name="zixiReceiverKey" label="Zixi receiver key" />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const AlarmsForm = () => {
  const { saving } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)

  return (
    <Grid container>
      <Grid item xs={12}>
        <Form id="alarms-form" translate="no" noValidate>
          <Paper title="Alarms" sx={classes.noMarginTop}>
            <Checkbox
              name="showAllNimbra400Alarms"
              label="Show all Nimbra 400 alarms"
              tooltip="Include alarms for objects on connected Nimbra 400-series appliances not directly related to Nimbra Edge"
            />
          </Paper>

          <ButtonsPane
            main={{
              Save: { primary: true, savingState: saving, type: 'submit' },
            }}
          />
        </Form>
      </Grid>
    </Grid>
  )
}

const defaultExpFeatures = Object.keys(ExpFeatures).reduce((a, key) => {
  return { ...a, [key]: false }
}, {})

export const Settings = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [{ settingsTab = GlobalSettingsTabs.settings }, setPageParams] = usePageParams()
  useEffect(() => {
    dispatch(getSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { settings, user, tls } = useSelector(
    ({ settingsReducer, userReducer }: GlobalState) => ({
      settings: settingsReducer.settings,
      tls: settingsReducer.tls,
      user: userReducer.user as User,
    }),
    shallowEqual,
  )
  const onSubmit = (settingsPayload: GlobalSettings) => {
    dispatch(saveSettings(settingsPayload))
  }
  const onTlsSubmit = (tlsPayload: TlsCertWrite) => {
    dispatch(saveTls(tlsPayload))
  }
  if (user && user.role !== Role.super) return null

  const edgeTabs = Object.values(GlobalSettingsTabs)
  const lociDisallowedTabs = [GlobalSettingsTabs['customization'], GlobalSettingsTabs.licenses]
  const lociTabs = edgeTabs.filter((v) => !lociDisallowedTabs.includes(v))
  const globalSettingsTabs = !IS_CONNECT_IT ? edgeTabs : lociTabs

  return (
    <Wrapper name="Global settings">
      <Paper sx={{ ...classes.noMarginBottom, paddingBottom: 0 }}>
        <Tabs
          value={settingsTab}
          variant="fullWidth"
          onChange={(_, val) => {
            if (val === 'doc') return
            setPageParams({ settingsTab: val })
          }}
          sx={classes.container}
          textColor="inherit"
        >
          {globalSettingsTabs.map((v) => (
            <Tab key={`tab-${v}`} label={v} value={v} />
          ))}
          <Tab
            label="API Document (Experimental)"
            value="doc"
            component="a"
            href="/docs/edge-api.html"
            target="_blank"
            icon={<OpenInNew />}
            iconPosition="end"
          />
        </Tabs>
      </Paper>
      <Pendable pending={!settings}>
        {settingsTab === GlobalSettingsTabs.settings && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
                expFeatures: Object.assign({}, defaultExpFeatures, settings?.expFeatures),
              } as GlobalSettings
            }
            component={SettingsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['tls certificate'] && (
          <Formik
            onSubmit={({ key, cert }, actions) => {
              actions.setSubmitting(false)
              onTlsSubmit({ key, cert })
            }}
            enableReinitialize={true}
            initialValues={{ ...tls, cert: '', key: '' } as TlsCertWrite & TlsCertRead}
            component={TlsForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs['customization'] && (
          <>
            <Grid container>
              <Paper title="Customization" sx={{ ...classes.noMarginBottom, ...classes.noMarginTop }}>
                <Grid item xs={12} lg={6}>
                  <FileInput label="Login page logo" name={ImageName.product} id="login-page-logo-file-picker" />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FileInput
                    label="Top left logo"
                    name={ImageName.serviceProvider}
                    text="Best ratio is 5:3"
                    id="left-corner-file-picker"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FileInput label="Favicon" name={ImageName.favicon} id="favicon-file-picker" />
                </Grid>
              </Paper>
              <Formik
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false)
                  onSubmit(values)
                  dispatch(saveImages())
                }}
                initialValues={
                  {
                    ...settings,
                  } as GlobalSettings
                }
                component={CustomizationForm}
              />
            </Grid>
          </>
        )}
        {settingsTab === GlobalSettingsTabs.licenses && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={LicenseForm}
          />
        )}
        {settingsTab === GlobalSettingsTabs.alarms && (
          <Formik
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
              onSubmit(values)
            }}
            initialValues={
              {
                ...settings,
              } as GlobalSettings
            }
            component={AlarmsForm}
          />
        )}
      </Pendable>
    </Wrapper>
  )
}
