import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Tooltip from './Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Circle from '@mui/icons-material/FiberManualRecord'
import Round from '@mui/icons-material/FiberManualRecordOutlined'
import HelpOutline from '@mui/icons-material/HelpOutline'
import LinkOff from '@mui/icons-material/LinkOff'
import ReducedRedundancy from '@mui/icons-material/CallMerge'
import VideocamOff from '@mui/icons-material/VideocamOff'
import NotInterested from '@mui/icons-material/NotInterested'
import { Theme } from '@mui/material/styles'

import {
  Appliance,
  ApplianceConnectionState,
  ApplianceStatus,
  Input,
  InputAdminStatus,
  InputOperStatus,
  InputStatus,
  LimitedAppliance,
  OutputAdminStatus,
  OutputOperStatus,
  OutputStatus,
} from 'common/api/v1/types'
import { AppDispatch, GlobalState } from '../../store'
import { registerOutputObserver, unregisterOutputObserver } from '../../redux/actions/outputsActions'
import { registerApplianceObserver, unregisterApplianceObserver } from '../../redux/actions/applianceActions'
import { registerInputObserver, unregisterInputObserver } from '../../redux/actions/inputsActions'
import { runningDifferentSoftwareVersion } from '../../utils'

const styles = {
  inlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginLeft: (theme: Theme) => theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '34px',
    height: '34px',
    pointerEvents: 'all',
    '& svg': {
      display: 'block',
    },
  },
  warning: {
    color: (theme: Theme) => theme.palette.warning.main,
  },
  error: {
    color: (theme: Theme) => theme.palette.error.main,
  },
  success: {
    color: (theme: Theme) => theme.palette.success.main,
  },
}

interface InputIndicatorProps {
  status: InputOperStatus
  disabled?: boolean
}

interface OutputIndicatorProps {
  status: OutputOperStatus
  disabled?: boolean
}

interface ApplianceIndicatorProps {
  status: ApplianceStatus
  restarting?: boolean
  softwareMismatch?: boolean
}

function titleFromInputStatus(status: InputOperStatus) {
  switch (status) {
    case InputOperStatus.notConfigured:
      return 'Input is not configured'
    case InputOperStatus.metricsMissing:
      return 'Metrics missing:'
    case InputOperStatus.allOk:
      return 'Everything is ok'
    case InputOperStatus.inputError:
      return 'Input error:'
    case InputOperStatus.tr101290Priority1Error:
      return 'TR 101 290 Prio 1 error:'
    case InputOperStatus.transportError:
      return 'Transport error:'
    case InputOperStatus.alarm:
      return 'Alarm:'
    case InputOperStatus.reducedRedundancy:
      return 'Reduced redundancy:'
  }
}
function titleFromOutputStatus(status: OutputOperStatus) {
  switch (status) {
    case OutputOperStatus.notConfigured:
      return 'Not configured:'
    case OutputOperStatus.metricsMissing:
      return 'Metrics missing:'
    case OutputOperStatus.tr101290Priority1Error:
      return 'TR 101 290 Prio 1 error:'
    case OutputOperStatus.reducedRedundancy:
      return 'Reduced redundancy:'
    case OutputOperStatus.allOk:
      return 'Everything is ok'
    case OutputOperStatus.notAcknowledged:
      return 'Not acknowledged:'
    case OutputOperStatus.inputError:
      return 'Input error:'
    case OutputOperStatus.outputError:
      return 'Output error:'
    case OutputOperStatus.alarm:
      return 'Alarm:'
  }
}

const InputIndicatorIcon = ({ status, disabled }: InputIndicatorProps) => {
  if (disabled) return <NotInterested color="disabled" />
  switch (status) {
    case InputOperStatus.allOk:
      return <Circle sx={styles.success} />
    case InputOperStatus.inputError:
      return <VideocamOff sx={styles.error} />
    case InputOperStatus.notConfigured:
      return <Round sx={styles.success} />
    case InputOperStatus.tr101290Priority1Error:
      return <Circle sx={styles.warning} />
    case InputOperStatus.transportError:
      return <LinkOff sx={styles.error} />
    case InputOperStatus.reducedRedundancy:
      return <ReducedRedundancy sx={styles.success} />
    case InputOperStatus.metricsMissing:
    default:
      return <HelpOutline sx={styles.warning} />
  }
}

const OutputIndicatorIcon = ({ status, disabled }: OutputIndicatorProps) => {
  if (disabled) {
    return <NotInterested color="disabled" />
  }
  switch (status) {
    case OutputOperStatus.allOk:
      return <Circle sx={styles.success} />
    case OutputOperStatus.notAcknowledged:
      return <HelpOutline sx={styles.success} />
    case OutputOperStatus.inputError:
      return <VideocamOff sx={styles.error} />
    case OutputOperStatus.notConfigured:
      return <Round sx={styles.success} />
    case OutputOperStatus.outputError:
      return <LinkOff sx={styles.error} />
    case OutputOperStatus.reducedRedundancy:
      return <ReducedRedundancy sx={styles.success} />
    case OutputOperStatus.tr101290Priority1Error:
      return <Circle sx={styles.warning} />
    case OutputOperStatus.alarm: // fallthrough
    case OutputOperStatus.metricsMissing: // fallthrough
    default:
      return <HelpOutline sx={styles.warning} />
  }
}

const ApplianceIndicatorIcon = ({ status, restarting, softwareMismatch }: ApplianceIndicatorProps) => {
  if (restarting) {
    return <CircularProgress size={25} />
  }
  switch (status.state) {
    case ApplianceConnectionState.connected:
      return <Circle sx={softwareMismatch ? styles.warning : styles.success} />
    case ApplianceConnectionState.neverConnected:
      return <LinkOff sx={styles.error} />
    // return <NotInterested color="disabled" />
    case ApplianceConnectionState.missing:
      return <Circle sx={styles.error} />
    default:
      return null
  }
}

export const NetworkIndicator = ({ enabled }: { enabled: boolean }) => {
  if (!enabled)
    return (
      <Tooltip title={'Disabled'}>
        <NotInterested color="disabled" />
      </Tooltip>
    )
  return (
    <Tooltip title={'Enabled'}>
      <Circle sx={styles.success} />
    </Tooltip>
  )
}

export const AuditOperationIndicator = ({ error }: { error?: string }) => {
  if (error)
    return (
      <Tooltip title={`Operation failed with error: ${error}`}>
        <Circle sx={styles.error} />
      </Tooltip>
    )
  return (
    <Tooltip title={'Success'}>
      <Circle sx={styles.success} />
    </Tooltip>
  )
}

interface InputHealthIndicatorProps {
  status?: InputStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
}
/**
 * Input health indicator
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param disabled - whether input is disabled
 */
export const InputHealthIndicator: React.FunctionComponent<InputHealthIndicatorProps> = ({
  status,
  inline,
  noText,
  disabled,
}) => {
  if (!status) {
    status = {
      title: 'Unknown',
      state: InputOperStatus.metricsMissing,
    }
  }
  const titlePrefix = titleFromInputStatus(status.state)
  const title = `${titlePrefix}${status.title ? ` ${status.title}` : ''}`
  return inline || noText ? (
    <Box sx={styles.inlineWrapper}>
      <InputIndicatorIcon status={status.state} disabled={disabled} />
      {!noText && <span>{disabled ? 'Disabled' : title}</span>}
    </Box>
  ) : (
    <Tooltip title={disabled ? 'Input is disabled' : title}>
      <Box sx={styles.container}>
        <div>
          <InputIndicatorIcon status={status.state} disabled={disabled} />
        </div>
      </Box>
    </Tooltip>
  )
}

interface OutputHealthIndicatorProps {
  status?: OutputStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
}

/**
 * Output health indicator
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param disabled - whether output is disabled
 */
export const OutputHealthIndicator: React.FunctionComponent<OutputHealthIndicatorProps> = ({
  status,
  inline,
  noText,
  disabled,
}) => {
  if (!status) {
    status = {
      title: 'Unknown',
      state: OutputOperStatus.metricsMissing,
    }
  }
  const prefix = titleFromOutputStatus(status.state)
  const title = disabled ? 'Output is disabled' : `${prefix}${status.title ? ` ${status.title}` : ''}`
  return inline || noText ? (
    <Box sx={styles.inlineWrapper}>
      <OutputIndicatorIcon status={status.state} disabled={disabled} />
      {!noText && <span>{title}</span>}
    </Box>
  ) : (
    <Tooltip title={title}>
      <Box sx={styles.container}>
        <div>
          <OutputIndicatorIcon status={status.state} disabled={disabled} />
        </div>
      </Box>
    </Tooltip>
  )
}

interface ApplianceHealthIndicatorProps {
  status: ApplianceStatus
  disabled?: boolean
  inline?: boolean
  noText?: boolean
  restarting?: boolean
  softwareMismatch?: boolean
}
/**
 * Appliance health indicator
 * @param status - fetched status
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 * @param restarting - whether the appliance is restarting
 * @param softwareMismatch - wheter the appliance software version differ fron buildInfo version
 */
const ApplianceHealthIndicator: React.FunctionComponent<ApplianceHealthIndicatorProps> = ({
  status,
  inline,
  noText,
  restarting,
  softwareMismatch,
}) => {
  const title = softwareMismatch ? (
    <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
      {status.title}
      {'\n'}Software version is outdated, restart appliance to update
    </div>
  ) : (
    status.title
  )
  return inline || noText ? (
    <Box sx={styles.inlineWrapper}>
      <ApplianceIndicatorIcon status={status} restarting={restarting} />
      {!noText && <span>{status.title}</span>}
    </Box>
  ) : (
    <Tooltip title={restarting ? 'Restarting' : title} placement={'top'}>
      <Box sx={styles.container}>
        <div>
          <ApplianceIndicatorIcon status={status} restarting={restarting} softwareMismatch={softwareMismatch} />
        </div>
      </Box>
    </Tooltip>
  )
}

interface AutoUpdatingInputHealthIndicatorProps {
  initialInput: Pick<Input, 'id' | 'health' | 'adminStatus'>
  inline?: boolean
  noText?: boolean
}

interface AutoUpdatingOutputHealthIndicatorProps {
  outputId: string
  inline?: boolean
  noText?: boolean
}

interface AutoUpdatingApplianceHealthIndicatorProps {
  applianceId: string
  inline?: boolean
  noText?: boolean
}

/**
 * Logic for input health indicator with fetching
 * @param initialInput - input to show the health of.
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 */
export const AutoUpdatingInputHealthIndicator = ({
  initialInput,
  inline,
  noText,
}: AutoUpdatingInputHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()

  const inputId = initialInput.id
  useEffect(() => {
    if (!inputId) {
      return
    }

    dispatch(registerInputObserver({ inputId }))
    return () => {
      dispatch(unregisterInputObserver({ inputId }))
    }
  }, [inputId])

  const observedInputs = useSelector(({ inputsReducer }: GlobalState) => inputsReducer.observedInputs, shallowEqual)

  if (!inputId) {
    return null
  }

  const input = [...observedInputs, initialInput].find((i) => i?.id === inputId)
  if (!input) {
    return null
  }

  const health = input.health
  if (!health) {
    return null
  }

  return (
    <InputHealthIndicator
      status={health}
      inline={inline}
      noText={noText}
      disabled={input.adminStatus === InputAdminStatus.off}
    />
  )
}

/**
 * Logic for output health indicator with fetching
 * @param output - output to show the health of
 * @param inline - whether it should be inline component
 * @param noText - whether we should hide the text for inline component
 */
export const AutoUpdatingOutputHealthIndicator = ({
  outputId,
  inline,
  noText,
}: AutoUpdatingOutputHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(registerOutputObserver({ outputId }))
    return () => {
      dispatch(unregisterOutputObserver({ outputId }))
    }
  }, [outputId])

  const selectorOutputs = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.outputs, shallowEqual)
  const selectorOutput = useSelector(({ outputsReducer }: GlobalState) => outputsReducer.output, shallowEqual)
  const output = [...selectorOutputs, selectorOutput].find((o) => o?.id === outputId)

  if (!output) {
    return null
  }

  const health = output.health
  if (!health) {
    return null
  }

  return (
    <OutputHealthIndicator
      status={health}
      inline={inline}
      noText={noText}
      disabled={output.adminStatus === OutputAdminStatus.off}
    />
  )
}

export const ServiceOverviewApplianceHealthIndicator = ({
  applianceId,
  inline,
  noText,
}: AutoUpdatingApplianceHealthIndicatorProps) => {
  const serviceOverviewAppliances: Array<Appliance | LimitedAppliance> = useSelector(
    ({ serviceOverviewReducer }: GlobalState) => serviceOverviewReducer.appliances,
    shallowEqual,
  )

  if (!applianceId) {
    return null
  }

  const appliance = serviceOverviewAppliances.find((a) => a?.id == applianceId)
  return appliance && 'health' in appliance && appliance.health ? (
    <ApplianceHealthIndicator status={appliance.health} inline={inline} noText={noText} />
  ) : null
}

export const AutoUpdatingApplianceHealthIndicator = ({
  applianceId,
  inline,
  noText,
}: AutoUpdatingApplianceHealthIndicatorProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { buildInfo } = useSelector(({ buildInfoReducer }: GlobalState) => buildInfoReducer, shallowEqual)

  useEffect(() => {
    dispatch(registerApplianceObserver({ applianceId }))
    return () => {
      dispatch(unregisterApplianceObserver({ applianceId }))
    }
  }, [applianceId])

  const { appliances: selectorAppliances, appliance: selectorAppliance } = useSelector(
    ({ appliancesReducer }: GlobalState) => appliancesReducer,
    shallowEqual,
  )

  if (!applianceId) {
    return null
  }

  const appliance = selectorAppliances.find((a) => a?.id === applianceId) || selectorAppliance
  const controlSoftwareVersion = appliance?.version?.controlSoftwareVersion
  const dataSoftwareVersion = appliance?.version?.dataSoftwareVersion
  const softwareMismatch =
    runningDifferentSoftwareVersion(controlSoftwareVersion, buildInfo) ||
    runningDifferentSoftwareVersion(dataSoftwareVersion, buildInfo)

  return appliance && appliance.health ? (
    <ApplianceHealthIndicator
      status={appliance.health}
      inline={inline}
      noText={noText}
      restarting={appliance._restarting}
      softwareMismatch={softwareMismatch}
    />
  ) : null
}
