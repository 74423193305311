import { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import { Form, Formik, FormikProps } from 'formik'
import Grid from '@mui/material/Grid'

import { IpMappingRead, IpMappingType, IpMappingWrite } from 'common/api/v1/types'
import { Api, AppDispatch, GlobalState, useRoutes } from '../../store'
import { Autocomplete, ButtonsPane, Paper, SafeRouting, TextInput } from '../common/Form'
import Pendable from '../common/Pendable'
import Wrapper from '../common/Wrapper'
import {
  clearEditMapping,
  createIpMapping,
  getIpMapping,
  removeIpMapping,
  updateIpMapping,
  writeModelFromReadModel,
} from '../../redux/actions/addressMappingActions'
import { useConfirmationDialog, useRegionsSelector } from '../../utils'

const { regionApi } = Api

const getInitialState = (ipMapping?: IpMappingRead, chosenRegion?: { id: string; name: string }): IpMappingRead => {
  const region = chosenRegion ? chosenRegion : { id: '', name: '' }
  return (
    ipMapping || {
      privateIp: '',
      publicIp: '',
      internalIp: '',
      interRegionIp: '',
      region,
      type: IpMappingType.suggested,
      nics: [],
    }
  )
}

const MappingForm = (formik: FormikProps<IpMappingRead>, navigate: NavigateFunction) => {
  const dispatch = useDispatch<AppDispatch>()
  const showConfirmation = useConfirmationDialog()

  const routes = useRoutes()
  const { ipMapping, loading } = useSelector(
    ({ addressMappingReducer }: GlobalState) => addressMappingReducer,
    shallowEqual,
  )

  const warnAboutServiceDisruptions = (callback: () => Promise<any>) => {
    showConfirmation(
      () => void callback(),
      `This action could potentially disrupt related services that are currently active. Are you sure you want to proceed?`,
    )
  }

  const isEditing = !!ipMapping

  const nicIdentityString = (n: IpMappingRead['nics'][number]) => `${n.name} on ${n.appliance.name}`
  const identity = ipMapping?.nics ? `(${ipMapping.nics.map(nicIdentityString).join(', ')})` : ''

  useEffect(() => {
    formik.setSubmitting(loading)
  }, [formik, loading])

  const api = useCallback(regionApi.getRegions.bind(regionApi), [])
  return (
    <Grid container>
      <Grid item xs={12}>
        <SafeRouting enabled={formik.dirty && !formik.isSubmitting} />
        <Form id="user-form" translate="no" noValidate>
          <Paper
            title={
              formik.values.privateIp
                ? `Address mappings for ${formik.values.privateIp} ${identity}`
                : 'Create address mapping'
            }
          >
            <Autocomplete<IpMappingRead['region']>
              name="region"
              formik={formik}
              required={true}
              disabled={isEditing}
              api={api}
              defaultOption={isEditing ? formik.values.region : undefined}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option}
              optionComparator={(o1, o2) => o1.id === o2.id}
            />
            <TextInput
              name="privateIp"
              required={true}
              validators={{
                ip: {},
              }}
              label="Private address"
              disabled={isEditing}
              tooltip="Private address of the interface (used if no other address is configured)."
            />
            <TextInput
              name="publicIp"
              label="Public address"
              required={false}
              validators={{ ip: {} }}
              tooltip="Used by appliances connecting from outside the cluster. Can be a NATed address or set to the private address."
            />
            <TextInput
              name="internalIp"
              label="Internal address"
              validators={{ ip: {} }}
              tooltip="Used by thumb nodes in the same region."
            />
            <TextInput
              name="interRegionIp"
              label="Inter region address"
              validators={{ ip: {} }}
              tooltip="Used by video nodes in other regions."
            />
          </Paper>

          <ButtonsPane
            main={{
              Cancel: {
                onClick: () => navigate(routes.addressMappings()),
              },
              Save: {
                savingState: false,
                primary: true,
                onClick: () => warnAboutServiceDisruptions(formik.submitForm),
              },
            }}
            secondary={
              formik.values.privateIp && isEditing
                ? {
                    'Remove mapping': {
                      onClick: () =>
                        warnAboutServiceDisruptions(() =>
                          dispatch(removeIpMapping(writeModelFromReadModel(formik.values))),
                        ),
                      disabled: formik.values.type === IpMappingType.suggested,
                      tooltip:
                        formik.values.type === IpMappingType.suggested
                          ? "This is an unmapped address which can't be removed"
                          : undefined,
                    },
                  }
                : undefined
            }
          />
        </Form>
      </Grid>
    </Grid>
  )
}

export const Edit = () => {
  const { privateIp, region } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { ipMapping } = useSelector(({ addressMappingReducer }: GlobalState) => addressMappingReducer, shallowEqual)
  const { regions, loading } = useRegionsSelector({ rowsPerPage: '100', pageNumber: '0' })
  const chosenRegion = ipMapping?.region || (regions.length === 1 ? regions[0] : undefined)

  const onSubmit = (editedIpMapping: IpMappingWrite) => {
    if (ipMapping) dispatch(updateIpMapping(editedIpMapping))
    else dispatch(createIpMapping(editedIpMapping))
  }

  useEffect(() => {
    privateIp && region && dispatch(getIpMapping({ privateIp, region }))
    return () => {
      dispatch(clearEditMapping())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper name="Address mappings" entityName={ipMapping ? ipMapping.privateIp : 'New'}>
      <Grid container spacing={0}>
        <Pendable pending={loading || (!!privateIp && !ipMapping)}>
          <Formik
            onSubmit={(values) => {
              onSubmit(writeModelFromReadModel(values))
            }}
            initialValues={getInitialState(ipMapping, chosenRegion)}
          >
            {(formik) => MappingForm(formik, navigate)}
          </Formik>
        </Pendable>
      </Grid>
    </Wrapper>
  )
}
