import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '../../common/Tooltip'
import Delete from '@mui/icons-material/Delete'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Stop from '@mui/icons-material/Stop'

import { Output, OutputAdminStatus } from 'common/api/v1/types'
import { pluralize, usePageParams } from '../../../utils'
import { Api, AppDispatch, GlobalState } from '../../../store'
import { DraftActions, draftOutputs, enableOutputs } from '../../../redux/actions/outputsActions'
import { FilterBy, filtersToUrlParams, urlParamsToFilters } from '../../common/Filters/FilterView/FilterTemplate'
import Button from '@mui/material/Button'
import { FilterPopup } from '../../common/Filters/FilterView/AddFilterPopup'
import { ReduxSelectedFilters } from '../../common/Filters/FilterView/SelectedFilters'
import { makeListOutputFilter, mapOutputFilterToUrlParam, mapUrlParamToOutputFilter } from './listOutputFilter'
import { OutputsRequestParams } from '../../../api/nm-types'

interface CommonActionsProps {
  selected: Array<Output['id']>
  setSelected: React.Dispatch<React.SetStateAction<string[]>>

  outputs: Output[]
}

const BatchEnableButton = ({ selected, setSelected }: Pick<CommonActionsProps, 'selected' | 'setSelected'>) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.off)

  const onEnableClick = () => {
    dispatch(enableOutputs(selected))
    dispatch(draftOutputs({ outputs: [] }))
    setSelected([])
  }

  return (
    <Tooltip title={'Enable selected outputs'}>
      <span>
        <IconButton aria-label="Enable" disabled={!enable} onClick={onEnableClick} data-test-id="batch-enable">
          <PlayArrow />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const BatchDisableButton = ({ selected }: { selected: Array<Output['id']> }) => {
  const outputs = useSelector((state: GlobalState) => state.outputsReducer.outputs, shallowEqual)
  const dispatch = useDispatch<AppDispatch>()

  const selectedOutputs = outputs.filter(({ id }) => selected.includes(id))
  const enable = selected.length > 0 && selectedOutputs.some(({ adminStatus }) => adminStatus === OutputAdminStatus.on)

  const onDisableClick = () => {
    dispatch(draftOutputs({ action: DraftActions.disable, outputs: selectedOutputs }))
  }

  return (
    <Tooltip title={'Disable selected outputs'}>
      <span>
        <IconButton aria-label="Disable" disabled={!enable} onClick={onDisableClick} data-test-id="batch-disable">
          <Stop />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CommonActions = ({ outputs, selected, setSelected }: CommonActionsProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const outputsMap = outputs.reduce<{ [key: string]: Output }>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const { showButtons } = selected.reduce(
    ({ showButtons }, id) => {
      const output = outputsMap[id]
      return {
        showButtons: Boolean(showButtons && output),
      }
    },
    { showButtons: true },
  )

  const onDeleteClick = () =>
    dispatch(draftOutputs({ action: DraftActions.delete, outputs: selected.map((id) => outputsMap[id]) }))

  const [filterPopupAnchor, setFilterPopupAnchor] = React.useState<HTMLElement | undefined>(undefined)
  const closePopup = () => {
    setFilterPopupAnchor(undefined)
  }
  const closePopupAndSaveFilters = (savedFilters: FilterBy[]) => {
    closePopup()
    populateUrlWithSelectedFilters(savedFilters)
  }

  const [_, setUrlParams] = usePageParams<Omit<OutputsRequestParams, 'rowsPerPage' | 'asc' | 'desc'>>()
  const { cachedUrlParams } = useSelector(({ urlParamReducer }: GlobalState) => urlParamReducer, shallowEqual)
  const cachedRequestParams = cachedUrlParams['outputs']
  const appliedFilters = cachedRequestParams ? urlParamsToFilters(cachedRequestParams, mapUrlParamToOutputFilter) : []
  const populateUrlWithSelectedFilters = (filters: FilterBy[]) => {
    const urlParams = filtersToUrlParams(filters, appliedFilters, mapOutputFilterToUrlParam)
    setUrlParams({ ...urlParams, pageNumber: '0' }) // Reset page to 0 when applying filters
  }

  return (
    <Toolbar disableGutters>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button variant="contained" onClick={(e) => setFilterPopupAnchor(e.currentTarget)}>
          Add filter
        </Button>
        <FilterPopup
          popupAnchor={filterPopupAnchor}
          onApplyClicked={closePopupAndSaveFilters}
          onCancelClicked={closePopup}
          initialFilters={appliedFilters}
          availableFilters={makeListOutputFilter({
            applianceApi: Api.appliancesApi,
            groupApi: Api.groupsApi,
            inputApi: Api.inputApi,
            regionApi: Api.regionApi,
          })}
        />
        <ReduxSelectedFilters
          urlParamCacheKey={'outputs'}
          mapUrlParamToFilterFn={mapUrlParamToOutputFilter}
          mapFilterToUrlParamFn={mapOutputFilterToUrlParam}
        />
      </div>

      <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {!!selected.length && (
          <Typography sx={{ flexGrow: 1 }} variant="subtitle1">
            {pluralize(selected.length, 'output')} selected
          </Typography>
        )}
        {!!selected.length && showButtons && (
          <div style={{ marginLeft: 8 }}>
            <Tooltip title={'Delete selected outputs'}>
              <span>
                <IconButton aria-label="Delete" onClick={onDeleteClick} data-test-id="delete-selected">
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
            <BatchDisableButton selected={selected} />
            <BatchEnableButton selected={selected} setSelected={setSelected} />
          </div>
        )}
      </div>
    </Toolbar>
  )
}

export default CommonActions
