import { Paper, Select, TextInput } from '../../../components/common/Form'
import { MAX_MAX_BITRATE_MBPS } from '../Edit/InputForm'

const MAX_FRAMERATE = 300

export const TranscodingSection = () => {
  return (
    <Paper title="Transcoding">
      <TextInput
        label={'Bitrate (Mbps)'}
        name="transcodeBitrateMbps"
        noNegative
        validators={{
          number: {
            lessThanOrEqualTo: MAX_MAX_BITRATE_MBPS,
            greaterThan: 0,
            message: `Must be greater than zero and no more than ${MAX_MAX_BITRATE_MBPS}`,
          },
        }}
        type="number"
        tooltip="Choose the compression level by selecting a bitrate for the transformed input"
      />
      <TextInput
        label={'Framerate'}
        name="deriveFrom.ingestTransform.ffmpegParams.framerate"
        noNegative
        validators={{
          number: {
            lessThanOrEqualTo: MAX_FRAMERATE,
            greaterThan: 0,
            message: `Must be greater than zero and no more than ${MAX_FRAMERATE}`,
          },
        }}
        type="number"
        tooltip="Convert framerate up or down, using frame interpolation"
      />
      <Select
        label="Codec"
        name="deriveFrom.ingestTransform.ffmpegParams.codec"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'H.264 (AVC)', value: 'h264' },
          { name: 'H.265 (HEVC)', value: 'h265' },
        ]}
        tooltip="Change codec using hardware acceleration"
      />
      <Select
        label="Pixel format"
        name="deriveFrom.ingestTransform.ffmpegParams.pixelFormat"
        options={[
          { name: 'Keep original', value: '' },
          { name: 'YUV 4:2:0 Progressive', value: 'yuv420p' },
          { name: 'YUV 4:2:2 Progressive', value: 'yuv422p', disabled: true },
          { name: 'YUV 4:4:4 Progressive', value: 'yuv444p' },
        ]}
        tooltip="Pixel format choice depends on the hardware accelerator's capability"
      />
      <Select
        label="Overlay"
        name="deriveFrom.ingestTransform.ffmpegParams.overlay"
        options={[
          { name: 'None', value: '' },
          { name: 'Net Insight demo', value: 'demo' },
        ]}
        tooltip="Add a channel logo or other overlays to the transformed input"
      />
    </Paper>
  )
}
