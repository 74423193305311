import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Appliance, Pcap } from 'common/api/v1/types'
import { Paper } from '../../../common/Form'
import { Table } from '../../../common/Table'
import PaginatedList from '../../../common/SelfStatePaginatedList'
import { PaginatedRequestParams } from '../../../../api/nm-types'
import { Api, AppDispatch } from '../../../../store'
import Box from '@mui/material/Box'
import Cached from '@mui/icons-material/Cached'
import MoreVert from '@mui/icons-material/MoreVert'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import DeleteIcon from '@mui/icons-material/Delete'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../../../../redux/actions/notificationActions'
import { styles } from '../../../../Common'

function downloadPcap(applianceId: string, pcap: Pcap) {
  document.location.href = `/api/appliance/${applianceId}/pcap/${pcap.id}`
  return 1
}

const { appliancesApi } = Api

export interface PcapsProps {
  appliance: Appliance
}
export const Pcaps: React.FunctionComponent<PcapsProps> = ({ appliance }) => {
  const [updateOn, setUpdateOn] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [postFetchActions, setPostFetchActions] = useState<Array<() => void>>([])

  const titleComponent = (
    <div style={{ display: 'flex', paddingBottom: 16 }}>
      <Typography variant="h2">Packet captures</Typography>
      <IconButton disabled={isFetching} onClick={() => !isFetching && triggerFetch()}>
        <Cached sx={isFetching ? styles.rotating : {}} />
      </IconButton>
    </div>
  )

  const triggerFetch = (postFetchAction?: () => void) => {
    if (postFetchAction) {
      setPostFetchActions((prevState) => [...prevState, postFetchAction])
    }
    setUpdateOn((prevState) => !prevState)
  }
  const onFetchComplete = ({ loading }: { loading?: boolean }) => {
    if (isFetching !== loading) {
      setIsFetching(loading ?? false)
    }
    if (!loading && postFetchActions.length > 0) {
      postFetchActions.forEach((a) => a())
      setPostFetchActions([])
    }
  }

  return (
    <Paper title={titleComponent} collapsible collapsed>
      <div style={{ width: '100%' }}>
        <PaginatedList<PaginatedRequestParams, Pcap>
          api={() => appliancesApi.listAppliancePcaps(appliance.id)}
          updateOn={updateOn}
          onListChange={onFetchComplete}
          hideSearch
          emptyMessage="no pcaps found"
          notFoundMessage="no matching pcaps"
          List={({ list }) => (
            <Table
              data={list}
              rowProps={(_pcap) => ({
                hover: true,
                sx: { cursor: 'pointer' },
              })}
              config={[
                {
                  title: 'Name',
                  getValue: ({ name }) => <span>{name}</span>,
                },
                {
                  title: 'Start time (UTC)',
                  getValue: ({ result }) => (
                    <span>{result?.startAt.toISOString().substring(0, 19).replace('T', ' ')}</span>
                  ),
                },
                {
                  title: 'Result',
                  getValue: ({ result }) => (
                    <span>{result?.error ? `Error: ${result?.stderr ?? result.error.code}` : 'Success'}</span>
                  ),
                },
                {
                  getValue: (pcap) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flex: 1,
                        height: '40px',
                      }}
                    >
                      <ActionMenu pcap={pcap} applianceId={appliance.id} requestRefresh={triggerFetch} />
                    </Box>
                  ),
                },
              ]}
            />
          )}
        />
      </div>
    </Paper>
  )
}

interface ActionMenuProps {
  pcap: Pcap
  applianceId: string
  requestRefresh: (onRefreshCompleted: () => Promise<any>) => void
}

export const ActionMenu = (props: ActionMenuProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { pcap } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLElement>, action: () => void) => {
    event.stopPropagation()
    setAnchorEl(null)
    action()
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <Box sx={{ display: 'flex', marginLeft: '16px' }}>
      <IconButton
        aria-label="open appliance input action menu"
        data-test-id="open-appliance-input-action-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ marginLeft: '-8px' }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test-id="appliance-input-action-menu"
        data-is-open={open ? 'true' : 'false'}
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '35ch',
          },
        }}
      >
        <MenuItem
          disabled={!!pcap.result?.error}
          data-test-id="download-pcap"
          onClick={(e) => {
            handleItemClick(e, () => downloadPcap(props.applianceId, pcap))
          }}
        >
          <ListItemIcon>
            <SaveAltIcon />
          </ListItemIcon>
          <ListItemText primary="Download" />
        </MenuItem>
        <MenuItem
          data-test-id="delete-pcap"
          onClick={(e) => {
            handleItemClick(e, () =>
              Api.appliancesApi
                .deletePcap(props.applianceId, pcap.id)
                .then(() => {
                  props.requestRefresh(() => dispatch(enqueueSuccessSnackbar('Pcap deleted')))
                })
                .catch((error) => {
                  dispatch(enqueueErrorSnackbar({ error, operation: 'delete pcap' }))
                }),
            )
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
    </Box>
  )
}
