import { FormikProps } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'
import get from 'lodash/get'

import { Address, HandoverMethod, Input, InputAdminStatus, OccupiedPort } from 'common/api/v1/types'
import { defaultResolution, defaultFrameRate, defaultScanMode, defaultTimestampResolution } from 'common/api/defaults'

import { Checkbox, Select, TextInput } from '../../../../common/Form'
import { createDefaultFiledValues, makeAddressOptions, PortToValidate } from '../../../../../utils'

import { RichOption } from 'src/components/common/Form/Select'
import { GlobalState } from '../../../../../store'
import { useEffect } from 'react'

export enum GeneratorFields {
  address = 'address',
  port = 'port',
  bitrate = 'bitrate',
  audioOnly = 'audioOnly',
  resolution = 'resolution',
  scanMode = 'scanMode',
  frameRate = 'frameRate',
  timestampResolution = 'timestampResolution',
  audioPid = 'audioPid',
  videoPid = 'videoPid',
}

export const generatorDefaults = () =>
  createDefaultFiledValues(Object.keys(GeneratorFields), ['audioOnly'], {
    resolution: defaultResolution,
    frameRate: defaultFrameRate,
    scanMode: defaultScanMode,
    timestampResolution: defaultTimestampResolution,
  })

interface GeneratorFormProps {
  form: FormikProps<Input>
  addresses: Array<Address>
  namePrefix: string
  occupiedPorts: OccupiedPort[]
  adminStatus: InputAdminStatus
  handoverMethod: HandoverMethod
}

const allowedFrameRates: RichOption[] = [
  {
    name: '30',
    value: '30',
  },
  {
    name: '60',
    value: '60',
  },
  {
    name: '25',
    value: '25',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '29.97',
    value: '29.97',
  },
  {
    name: '59.94',
    value: '59.94',
  },
]

const allowedResolutions: RichOption[] = [
  {
    name: '1280x720',
    value: '1280x720',
  },
  {
    name: '1920x1080',
    value: '1920x1080',
  },
]

const allowedScanModes: RichOption[] = [
  {
    name: 'progressive',
    value: 'progressive',
  },
  {
    name: 'interlaced - Top Frame First (TFF)',
    value: 'interlaced_tff',
  },
  {
    name: 'interlaced - Bottom Frame First (BFF)',
    value: 'interlaced_bff',
  },
]

const allowedTimestampResolutions: RichOption[] = [
  {
    name: 'seconds',
    value: 'seconds',
  },
  {
    name: 'milliseconds',
    value: 'milliseconds',
  },
]

const INTERNAL_PORT_RANGE_START = 30000

const GeneratorForm = ({
  form,
  addresses,
  namePrefix,
  occupiedPorts,
  adminStatus,
  handoverMethod,
}: GeneratorFormProps) => {
  const { devMode } = useSelector(({ settingsReducer }: GlobalState) => settingsReducer, shallowEqual)
  const addressSelector = `${namePrefix}.${GeneratorFields.address}`
  const portSelector = `${namePrefix}.${GeneratorFields.port}`
  const address = get(form.values, addressSelector)
  const port = get(form.values, portSelector)
  const isAudioOnly = !!get(form.values, `${namePrefix}.${GeneratorFields.audioOnly}`)

  // Newer generators don't allow user to select address and port - they are always created on lo0
  const isOldGeneratorWithCustomAddressAndPort =
    handoverMethod === HandoverMethod.udp && port && port < INTERNAL_PORT_RANGE_START
  useEffect(() => {
    if (address !== '127.0.0.1' && isOldGeneratorWithCustomAddressAndPort) {
      form.setFieldValue(addressSelector, '127.0.0.1', false)
    }
  }, [form, addressSelector, address])

  const portInfo: PortToValidate = {
    isInput: true,
    isPortDisabled: adminStatus === InputAdminStatus.off,
    mode: port.mode,
    existingLogicalPortsOnSameNic: occupiedPorts,
  }
  return (
    <>
      {isOldGeneratorWithCustomAddressAndPort && (
        <>
          <Select
            name={addressSelector}
            label="Address"
            required
            options={makeAddressOptions(get(form.values, addressSelector), addresses).filter(
              (a) => a.value === '127.0.0.1',
            )}
            disabled={true}
            newLine
            validators={{
              addressIn: { addresses },
            }}
          />
          <TextInput
            name={`${namePrefix}.${GeneratorFields.port}`}
            label="Port"
            type="number"
            disabled={true}
            noNegative
            tooltip="It is no longer possible to choose/edit the port for generator inputs. For new generator inputs, this port will be automatically assigned and not visible in Network Manager."
            validators={{
              port: { disallowInternal: false, isUdp: true },
              isPortAvailable: portInfo,
            }}
          />
        </>
      )}

      <Checkbox name={`${namePrefix}.${GeneratorFields.audioOnly}`} label="Audio only" />

      <TextInput
        type="number"
        label="Audio Pid"
        name={`${namePrefix}.${GeneratorFields.audioPid}`}
        newLine
        validators={{
          pid: {},
          not: {
            invalidValue: get(form.values, `${namePrefix}.${GeneratorFields.videoPid}`),
            errorMsg: `Must not be same as video pid`,
          },
        }}
      />
      <TextInput
        type="number"
        label="Video Pid"
        name={`${namePrefix}.${GeneratorFields.videoPid}`}
        disabled={isAudioOnly}
        validators={{
          pid: {},
          not: {
            invalidValue: get(form.values, `${namePrefix}.${GeneratorFields.audioPid}`),
            errorMsg: `Must not be same as audio pid`,
          },
        }}
      />

      <Select
        name={`${namePrefix}.${GeneratorFields.resolution}`}
        label="Resolution"
        options={allowedResolutions}
        newLine
        disabled={isAudioOnly}
      />
      {devMode && (
        <Select
          name={`${namePrefix}.${GeneratorFields.scanMode}`}
          label="Scan mode"
          options={allowedScanModes}
          newLine
          disabled={isAudioOnly}
        />
      )}
      <Select
        name={`${namePrefix}.${GeneratorFields.frameRate}`}
        label="Frame rate"
        options={allowedFrameRates}
        newLine
        disabled={isAudioOnly}
      />
      <Select
        name={`${namePrefix}.${GeneratorFields.timestampResolution}`}
        label="Timestamp resolution"
        options={allowedTimestampResolutions}
        newLine
        disabled={isAudioOnly}
      />
    </>
  )
}

export default GeneratorForm
